<template>
    <div class="content">

        <commonTitle></commonTitle>
        <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm"
                 v-loading="loading"
                 element-loading-text="添加楼幢过程中，请稍后..."
        >
            <el-form-item label="小区名称" >
                <el-input v-model="ruleForm.villageName" disabled="true"></el-input>
            </el-form-item>
            <el-form-item label="楼幢号" prop="towerNum">
                <el-input v-model="ruleForm.towerNum" placeholder="请输入楼幢号"></el-input>
            </el-form-item>
            <el-form-item label="楼幢类型" prop="type">
                <el-col :span="24">
                    <el-select v-model="ruleForm.type" placeholder="请选择楼幢类型" style="width: 100%" filterable
                               @change="selectOne">
                        <el-option
                                v-for="item in towerTypeList"
                                :key="item.id"
                                :label="item.label"
                                :value="item.id">
                        </el-option>
                    </el-select>
                </el-col>
            </el-form-item>
            <el-form-item label="是否有电梯" prop="iselevator">
                <el-col :span="24">
                    <el-select v-model="ruleForm.iselevator" placeholder="请选择电梯类型" style="width: 100%" filterable>
                        <el-option
                                v-for="item in dtList"
                                :key="item.id"
                                :label="item.label"
                                :value="item.id">
                        </el-option>
                    </el-select>
                </el-col>
            </el-form-item>
            <el-form-item label="建筑面积" prop="coveredarea">
                <el-input v-model.number="ruleForm.coveredarea" autocomplete="off" placeholder="请输入楼幢建筑面积"
                          onkeyup="this.value=this.value.replace(/[^\d.]/g,'');"
                          maxlength="8"
                ></el-input>
            </el-form-item>
            <el-form-item label="楼层数" prop="floorNum">
                <el-input type="text" v-model.number="ruleForm.floorNum" placeholder="请输入楼层数"
                          onkeyup="this.value=this.value.replace(/[^\d.]/g,'');"
                          maxlength="4"
                ></el-input>
            </el-form-item>
            <el-form-item label="单元数" prop="unitNum">
                <el-input type="text" v-model.number="ruleForm.unitNum" placeholder="请输入单元数"
                          onkeyup="this.value=this.value.replace(/[^\d.]/g,'');"
                          maxlength="5"
                ></el-input>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" size="small" @click="submitForm('ruleForm')">保存</el-button>
                <el-button @click="back" size="small">返回</el-button>
            </el-form-item>
        </el-form>
    </div>
</template>

<script>
    import commonTitle from "../table/commonTitle";

    export default {
        name: "addTower",
        components: {
            commonTitle
        },
        data() {
            var checkVillageNum = (rule, value, callback) => {
                if (value) {
                    if (/[\u4E00-\u9FA5]/g.test(value)) {
                        callback(new Error('编号不能输入汉字'));
                    } else {
                        callback();
                    }
                }
                callback();
            }

            var checkCoveredArea = (rule, value, callback) => {

                if (!Number.isInteger(value)) {
                    callback(new Error('请输入数字值'));
                } else {
                    if (value > 10000000) {
                        callback(new Error('超过最大值10000000'));
                    } else {
                        callback();
                    }
                }

            };

            return {
                ruleForm: {
                    villageName: '',//小区名称
                    towerNum: '',  //楼幢号
                    villageId: '',   //小区Id
                    type: '', //楼幢类型
                    iselevator: '',   //是否有电梯
                    coveredarea: '',  //建筑面积
                    unitNum: '', //单元数
                    paystandardId: '',//缴费标准
                    floorNum: ''//楼层数
                },
                rules: {
                    towerNum: [
                        {required: true, message: '请输入楼幢号', trigger: 'change'},
                        {validator: checkVillageNum, trigger: 'change'}
                    ],
                    type: [
                        {required: true, message: '请选择楼幢类型', trigger: 'change'}
                    ],
                    iselevator: [
                        {required: true, message: '请选择是否有电梯', trigger: 'change'}
                    ],
                    coveredarea: [
                        {required: true, message: '请输入建筑面积', trigger: 'change'},
                        {validator: checkCoveredArea, trigger: 'blur'}
                    ],
                    unitNum: [
                        {required: true, message: '请输入单元数', trigger: 'change'},
                        {type: 'number', message: '单元数必须为数字值'}
                    ],
                    floorNum: [
                        {required: true, message: '请输入楼层数', trigger: 'blur'},
                        {type: 'number', message: '楼层数必须为数字值'}
                    ],
                    paystandardId: [
                        {required: true, message: '请选择缴费标准', trigger: 'change'},
                    ]
                },
                towerTypeList: [],  //楼幢类型
                dtList: [],        //电梯类型
                payList: [],        //缴费标准
                url: this.$Config.base_server,
                loading: false
            };
        }, mounted() {
            //初始化数据
            this.ruleForm.villageName = JSON.parse(localStorage.getItem("villageData")).villageName;
            this.ruleForm.villageId = JSON.parse(localStorage.getItem("villageData")).villageId;
        }, methods: {
            selectOne(event, item) {
            }
            , submitForm(formName) {
                var _this = this;
                //立即创建按钮
                this.$refs[formName].validate(function (valid) {
                    if (valid) {
                        _this.loading = true;
                        //获取该小区下面的楼幢信息
                        //向后台提交数据
                        _this.ruleForm.villageId = JSON.parse(localStorage.getItem("villageData")).villageId;

                        $.ajax({
                            url: _this.url + "/api-public/opetower/save?access_token=" + localStorage.getItem("token"),
                            type: 'post',
                            data: JSON.stringify(_this.ruleForm),
                            contentType: 'application/json',
                            success: function (result) {
                                if (result.resp_code == 0) {
                                    _this.loading = false;
                                    //成功后
                                    _this.$confirm('楼幢添加成功,是否还要继续添加?', '提示', {
                                        confirmButtonText: '继续',
                                        cancelButtonText: '不在继续',
                                        type: 'warning'
                                    }).then(function () {
                                        //清空表单信息
                                        _this.$refs[formName].resetFields();
                                    }).catch(function () {
                                        //返回上一个路由
                                        _this.$router.go(-1);
                                    });

                                } else {
                                    _this.loading = false;
                                    //添加失败
                                    _this.$message({
                                        message: result.resp_msg,
                                        dangerouslyUseHTMLString: true,
                                        type: 'warning'
                                    });
                                }
                            }
                        });
                    } else {
                        console.log('error submit!!');
                        return false;
                    }
                });
            }, back() {
                this.$router.go(-1);
            }
        }, created() {
            //初始化数据
            var _this = this; //声明一个变量指向Vue实例this，保证作用域一致
            //获取楼幢类型信息
            axios.get(this.url + "/api-public/sysdict/list?key=tower_type&access_token=" + localStorage.getItem("token")).then(function (res) {
                _this.towerTypeList = res.data;
            });
            //获取电梯类型信息
            axios.get(this.url + "/api-public/sysdict/list?key=iselevator&access_token=" + localStorage.getItem("token")).then(function (res) {
                _this.dtList = res.data;
            });
        }
    }
</script>

<style scoped>

    .content {
        padding: 20px 111px;
        background: #fff;
    }

    .el-form {
        width: 600px;
        margin: 0 auto;
    }
</style>